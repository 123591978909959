<template>
  <Teleport v-if="mounted" :to="props.to" :disabled="props.disabled">
    <slot />
  </Teleport>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue';

defineOptions({
  name: 'VTeleport'
})

const props = defineProps<{
  to: string
  disabled?: boolean
}>()

const mounted = ref(false)

onMounted(() => {
  mounted.value = true
})
</script>